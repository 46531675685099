<template>
    <div class="menu" :class="{ 'menu-open': isOpen }">
        <div class="menu-header">
            <img
                src="/storage/assets/logos/rectangle_blanc.png"
                alt="Logo"
                class="logo"
                @click="
                    () => {
                        isMobile ? $emit('toggle') : null;
                        $router.push({
                            name: 'dashboard',
                        });
                    }
                "
            />
        </div>
        <div class="menu-content">
            <primary-button
                @click="
                    () => {
                        isMobile ? $emit('toggle') : null;
                        $router.push({
                            name: 'assistance',
                        });
                    }
                "
                class="mb-3 w-100 justify-content-center"
                style="background-color: #f0ad4e"
            >
                {{
                    $translate('components.dashboard.menu.assistance')
                }}</primary-button
            ><primary-button
                v-if="user.hasSearch"
                @click="
                    () => {
                        isMobile ? $emit('toggle') : null;
                        $router.push({
                            name: 'reservations-create',
                        });
                    }
                "
                class="mb-3 w-100 justify-content-center"
                color="green"
            >
                {{
                    $translate('components.dashboard.menu.book_a_bike')
                }}</primary-button
            >
            <primary-button
                v-if="user.hasOrder"
                @click="
                    $router.push({
                        name: 'bikes-catalog-cafeteria-list',
                    })
                "
                class="mb-3 w-100 justify-content-center"
                color="green"
            >
                {{
                    $translate('components.dashboard.menu.order_a_bike')
                }}</primary-button
            >
            <ul class="menu-sections" v-if="authStore.$state.user.isAdmin">
                <li
                    v-for="(category, index) in categories"
                    :key="index"
                    @click="toggleSubsections(index)"
                    v-show="user.permission?.includes(category.permission)"
                >
                    <span>{{ category.name }}</span>
                    <ul
                        class="menu-subsections"
                        v-show="category.isOpen"
                        @click.stop
                    >
                        <li
                            v-for="(
                                subCategory, subIndex
                            ) in category.subcategories"
                            :key="subIndex"
                            :class="{
                                'text-green': isCurrentRoute(subCategory.route),
                                'font-bold': isCurrentRoute(subCategory.route),
                            }"
                            v-show="
                                subCategory.access
                                    ? user.company?.conditions[0]?.access &&
                                      user.company?.conditions[0]?.access
                                          .split(',')
                                          .some(access =>
                                              subCategory.access.includes(
                                                  access.trim(),
                                              ),
                                          )
                                    : subCategory.permission
                                    ? user.permission?.includes(
                                          subCategory.permission,
                                      )
                                    : true
                            "
                            @click="
                                () => {
                                    isMobile ? $emit('toggle') : null;
                                    $router.push({
                                        name: subCategory.route,
                                        params: subCategory.params,
                                    });
                                }
                            "
                        >
                            {{ subCategory.name }}
                        </li>
                    </ul>
                </li>
            </ul>
            <div class="w-full" v-if="authStore.$state.user.isFleet">
                <h3 class="mb-4">Menu Fleet manager</h3>
                <template v-for="item in menuItems" :key="item.route">
                    <router-link
                        :to="{
                            name: item.route,
                            params: item.params || {},
                        }"
                        v-if="!item.access || hasAccess(item.access)"
                        class="p-2 w-full w-full block text-sm"
                        :class="{
                            'text-green font-bold': isCurrentRoute(item.route),
                            'text-white': !isCurrentRoute(item.route),
                        }"
                    >
                        <i :class="item.icon"></i>
                        {{ item.name }}
                    </router-link>
                </template>
            </div>
            <template v-if="user.documents?.length">
                <h3 class="mt-4 mb-4">Documents</h3>
                <template v-for="document in user.documents" :key="document.id">
                    <a
                        :href="
                            $apiUrl() +
                            '/storage/documents/companies/' +
                            user.companies_id +
                            '/' +
                            document.file_name
                        "
                        class="pl-2 w-full block"
                        target="_blank"
                    >
                        <span v-if="document.type == 'manual'">
                            Manuel d'utilisation
                        </span>
                        <span v-else-if="document.type == 'bike_policy'"
                            >Bike Policy</span
                        > </a
                    ><br />
                </template>
            </template>
        </div>
    </div>
</template>

<script setup>
import { useAuthStore } from '@/js/stores/authStore.js';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';

const authStore = useAuthStore();
const route = useRoute();

defineProps({
    isOpen: Boolean,
});

defineEmits(['toggle']);

const isCurrentRoute = computed(() => routeName => {
    return route.name === routeName;
});
const user = ref(authStore.$state.user);
const isMobile = ref(window.innerWidth < 768);

const toggleSubsections = index => {
    categories.value[index].isOpen = !categories.value[index].isOpen;
};

const menuItems = ref([
    {
        name: 'Liste des utilisateurs',
        route: 'fleet-users-list',
        icon: 'fa-solid fa-user',
        access: 'cafeteria, partage',
    },
    {
        name: 'Liste des vélos KAMEO',
        route: 'fleet-bikes-list',
        icon: 'fa-solid fa-bicycle',
    },
    {
        name: 'Liste des autres vélos',
        route: 'external-bikes-list',
        icon: 'fa-solid fa-person-biking',
    },
    {
        name: 'Liste des entretiens',
        route: 'fleet-entretiens-list',
        icon: 'fa-solid fa-hammer',
    },
    {
        name: 'Liste des offres',
        route: 'user-offers-list',
        access: 'cafeteria',
        icon: 'fa-solid fa-file',
    },
    {
        name: 'Liste des commandes',
        route: 'fleet-orders-list',
        access: 'cafeteria',
        icon: 'fa-solid fa-shopping-cart',
    },
    {
        name: 'Liste des bornes',
        route: 'boxes-list',
        access: 'partage',
        icon: 'fa-solid fa-box',
    },
    {
        name: 'Liste des réservations',
        route: 'reservations-list',
        access: 'partage',
        icon: 'fa-solid fa-calendar',
    },
    {
        name: 'Liste des propositions à confirmer',
        route: 'fleet-entretiens-list-waiting_answer',
        icon: 'fa-solid fa-hourglass-start',
    },
    {
        name: 'Liste des factures',
        route: 'fleet-bills-list',
        icon: 'fa-solid fa-file-invoice-dollar',
    },
    {
        name: 'Gestion des conditions',
        route: 'fleet-conditions-details',
        params: { id: authStore.user.companies_id }, // Access user data properly
        access: 'partage',
        icon: 'fa-solid fa-cogs',
    },
    {
        name: 'Calendrier',
        route: 'fleet-calendar',
        access: 'calendar',
        icon: 'fa-solid fa-calendar-alt',
    },
    {
        name: 'Statistiques',
        route: 'fleet-stats',
        access: 'partage',
        icon: 'fa-solid fa-chart-line',
    },
    {
        name: 'Quiz',
        route: 'fleet-quiz',
        access: 'quiz',
        icon: 'fa-solid fa-question-circle',
    },
]);
const hasAccess = requiredAccess => {
    const required = requiredAccess.split(',').map(a => a.trim());
    return required.some(access =>
        user.value.company?.conditions[0]?.access.includes(access),
    );
};

const categories = ref([
    {
        name: 'Utilisateurs / Entreprises',
        isOpen: false,
        permission: 'admin',
        subcategories: [
            {
                name: 'Liste des clients',
                route: 'companies-list',
            },
            {
                name: 'Liste des utilisateurs',
                route: 'users-list',
            },
            {
                name: 'Ajouter un client',
                route: 'client-create',
            },
            {
                name: 'Ajouter un utilisateur',
                route: 'user-create',
            },
        ],
    },
    {
        name: 'Offres',
        isOpen: false,
        permission: 'admin',
        subcategories: [
            {
                name: 'Liste',
                route: 'offers-list',
            },
            {
                name: 'Ajouter une offre',
                route: 'offers-create',
            },
            {
                name: 'Liste des intéressés',
                route: 'offers-interested',
            },
        ],
    },
    {
        name: 'Commandes',
        isOpen: false,
        permission: 'admin',
        subcategories: [
            {
                name: 'Liste',
                route: 'grouped-orders-list',
            },
            {
                name: 'Caisse',
                route: 'checkout',
            },
        ],
    },
    {
        name: 'Atelier',
        isOpen: false,
        permission: 'admin',
        subcategories: [
            {
                name: 'Entretiens',
                route: 'entretiens-list',
            },
            {
                name: 'Agenda',
                route: 'shop-schedule',
            },
            {
                name: 'Capacité',
                route: 'entretiens-capacity',
            },
        ],
    },
    {
        name: 'Tournée',
        isOpen: false,
        permission: 'admin',
        subcategories: [
            {
                name: 'Liste',
                route: 'tour-list',
            },
            {
                name: 'À proposer',
                route: 'entretiens-to-propose-for-tour',
            },
            {
                name: 'À confirmer',
                route: 'entretiens-to-confirm-for-tour',
            },
            {
                name: 'Carte',
                route: 'tours-map',
            },
        ],
    },
    {
        name: 'Catalogue',
        isOpen: false,
        permission: 'admin',
        subcategories: [
            {
                name: 'Liste des vélos',
                route: 'bikes-catalog-list',
            },
            {
                name: 'Liste des accessoires',
                route: 'accessories-catalog-list',
            },
            {
                name: 'Caractéristiques techniques',
                route: 'technical-characteristics',
            },
        ],
    },
    {
        name: 'Stock',
        isOpen: false,
        permission: 'admin',
        subcategories: [
            {
                name: 'Liste des vélos',
                route: 'bikes-stock-list',
            },
            {
                name: 'Liste des accessoires',
                route: 'accessories-stock-list',
            },
            {
                name: 'Encodage',
                route: 'stock-encode',
            },
            {
                name: 'Liste pour assurance',
                route: 'bikes-list-insurance',
            },
            {
                name: 'Inventaire',
                route: 'inventory',
            },
        ],
    },
    {
        name: 'Vélos externes',
        isOpen: false,
        permission: 'admin',
        subcategories: [
            {
                name: 'Liste',
                route: 'external-bikes-list',
            },
        ],
    },
    {
        name: 'Occasion',
        isOpen: false,
        permission: 'admin',
        subcategories: [
            {
                name: 'Liste',
                route: 'occasion-list',
            },
            {
                name: 'Ajouter',
                route: 'occasion-create',
            },
            {
                name: 'Rachat / vente Occasion',
                route: 'rachat-vente-occasion',
            },
        ],
    },
    {
        name: 'Factures',
        isOpen: false,
        permission: 'admin',
        subcategories: [
            {
                name: 'Liste',
                route: 'bills-list',
            },
            {
                name: 'A créer',
                route: 'bills-to-create',
            },
            {
                name: 'À envoyer',
                route: 'bills-to-send',
            },
            {
                name: 'Ticket à facturer',
                route: 'tickets-to-bill',
            },
            {
                name: 'Fin de leasing',
                route: 'leasing-end',
            },
            {
                name: 'Domiciliations',
                route: 'bills-sepa',
            },
            {
                name: 'Entretiens à facturer',
                route: 'entretiens-to-bill',
            },
            {
                name: 'Cartes cadeau',
                route: 'gift-cards',
            },
        ],
    },
    {
        name: 'Menu mécanicien',
        isOpen: true,
        permission: 'bikeMechanic',
        subcategories: [
            {
                name: 'Liste des vélos',
                route: 'fleet-bikes-list',
            },
        ],
    },
    {
        name: 'Amortissement',
        isOpen: false,
        permission: 'admin',
        subcategories: [
            {
                name: 'Montants à amortir',
                route: 'depreciation-overview',
            },
            {
                name: 'Lien factures > vélos',
                route: 'bills-bikes-link',
            },
            {
                name: 'Vélos à lier',
                route: 'bikes-to-link',
            },
            {
                name: 'Leasings revendus',
                route: 'external-financing-bikes',
            },
        ],
    },
    {
        name: 'Gestion des bornes',
        isOpen: false,
        permission: 'admin',
        subcategories: [
            {
                name: 'Liste des réservations',
                route: 'reservations-list',
            },
            {
                name: 'Liste des bornes',
                route: 'boxes-list',
            },
            {
                name: 'Statistiques',
                route: 'boxes-statistics',
            },
        ],
    },

    {
        name: 'Blog',
        isOpen: false,
        permission: 'admin',
        subcategories: [
            {
                name: 'Liste',
                route: 'blog-list',
            },
            {
                name: 'Ajouter',
                route: 'blog-create',
            },
        ],
    },
]);
if ([630, 631, 2922].includes(authStore.$state.user.id)) {
    const subcategories =
        authStore.$state.user.id === 2922
            ? [
                  {
                      name: 'Résultats du jour (atelier)',
                      route: 'statistics-day-by-day',
                  },
                  {
                      name: 'Marge',
                      route: 'statistics-margin',
                  },
              ]
            : [
                  {
                      name: 'Résultats du jour (atelier)',
                      route: 'statistics-day-by-day',
                  },
                  {
                      name: 'Marge',
                      route: 'statistics-margin',
                  },
                  {
                      name: 'Cash-Flow',
                      route: 'statistics-sales',
                  },
                  {
                      name: 'Bénéfice et forecast',
                      route: 'statistics-forecast',
                  },
              ];

    categories.value.push({
        name: 'Statistiques',
        isOpen: false,
        permission: 'admin',
        subcategories,
    });
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.menu {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 300px;
    background: url('/storage/assets/images/freestyleBike.jpg') center center
        no-repeat;
    background-size: cover;
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%);
    color: $greyColor;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: -1;
    }

    .menu-header {
        padding: 1rem;
        background-color: $greenColor;

        .logo {
            width: 100%;
            cursor: pointer;
        }
    }

    .menu-content {
        max-height: calc(100% - 100px);
        padding: 1rem;
        overflow-y: auto;

        .menu-sections {
            list-style: none;
            padding: 0;
            margin-bottom: 4rem;
            user-select: none;

            li {
                font-size: $primaryFontSize;
                margin-bottom: 0.5rem;
                cursor: pointer;
            }

            .menu-subsections {
                list-style: none;
                padding: 0;
                margin: 0;
                margin-left: 1rem;

                li {
                    font-size: $defaultFontSize;
                    margin-bottom: 0;
                }
            }
        }
    }
}

.menu-open {
    transform: translateX(0);
}
</style>
